<template>
  <h1>Hello TOKO, I'm Vue3</h1>
  <small>Print server variable : {{this.serverVar}}</small>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      serverVar:""
    }
  },
  methods: {
    encryptStr(string) {
      var md5 = require('md5');
      return md5(string);
    }
  },
  created() {
    this.serverVar = this.encryptStr(process.env.VUE_APP_VAR1)
  }
}
</script>

